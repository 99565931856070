require('./bootstrap');
//select 2
require('select2');

require('malihu-custom-scrollbar-plugin');

require('dropzone')

window.intlTelInput = intlTelInput = require("intl-tel-input");

//social share
require('jssocials/src/jssocials')
require('jssocials/src/jssocials.shares')




$("#user_select_business").change(function(){
    const business = $("#user_select_business").children("option:selected");
    const business_val = business.val();
    const url = business.data('url');
    if (business_val !== ''){
        $.ajax({
            url: url,
            type: "get",
            success: (data) => {
                if(data.status === "true"){
                    window.location = 'dashboard'
                }
            },
        });
    }
});


$("#search_business").keyup(function(){
    const url = $(this).data('url');
    if ($(this).val().length === 0){
        $("#businesses-suggestion-box").addClass('d-none')
    }else {
        $.ajax({
            type: "get",
            url: url,
            data:'keyword='+$(this).val(),
            success: function(data){
                $("#businesses-suggestion-box").removeClass('d-none').html(data);
            }
        });
    }
});

$(".clear_business").click(function(e){
    e.preventDefault();
    const url = $(this).data('url');
    $.ajax({
        url: url,
        type: "get",
        success: (data) => {
            if(data.status === "true"){
                window.location = data.redirect_url
            }
        },
    });
});

$(document).on("click",".business_result",function(e) {
    e.preventDefault();
    $("#businesses-suggestion-box").addClass('d-none')
    const url = $(this).data('url');
    $.ajax({
        url: url,
        type: "get",
        success: (data) => {
            if(data.status === "true"){
                window.location = data.redirect_url
            }
        },
    });
});

$(document).on("click",".like_business",function(e) {
    e.preventDefault();
    const url = $(this).data('url');
    const slug = $(this).data('business-slug');
    const like_id = '#like_business_'+slug
    const un_like_id = '#un_like_business_'+slug
    const count_id = '#count_likes_'+slug

    $.ajax({
        url: url,
        type: "get",
        success: (data) => {
            if (data.error) {
                toastr.options.closeButton = true;
                toastr.error(data.message, 'Error');
            } else {
                toastr.options.closeButton = true;
                toastr.success(data.message, 'Success');
                if (data.type === 'un-like'){
                    $(un_like_id).removeClass('d-none');
                    $(like_id).addClass('d-none');
                    $(count_id).text(data.count_business);
                }else {
                    $(un_like_id).addClass('d-none');
                    $(like_id).removeClass('d-none');
                    $(count_id).text(data.count_business);
                }
            }
        },
    });
});

$("#share").jsSocials({
    showLabel: false,
    showCount: false,
    shareIn: "popup",
    shares: [
        {
            share: "facebook",
            logo: "fab fa-facebook"
        },
        {
            share: "twitter",
            logo: "fab fa-twitter"
        },
        {
            share: "linkedin",
            logo: "fab fa-linkedin-in",
            shareUrl: "https://www.linkedin.com/sharing/share-offsite?url={url}",
        },
    ]
});

/* 1. Visualizing things on Hover - See next part for action on click */
$('#stars li').on('mouseover', function(){
    var onStar = parseInt($(this).data('value'), 10); // The star currently mouse on

    // Now highlight all the stars that's not after the current hovered star
    $(this).parent().children('li.star').each(function(e){
        if (e < onStar) {
            $(this).addClass('hover');
        }
        else {
            $(this).removeClass('hover');
        }
    });

}).on('mouseout', function(){
    $(this).parent().children('li.star').each(function(e){
        $(this).removeClass('hover');
    });
});


/* 2. Action to perform on click */
$('#stars li').on('click', function(){
    var onStar = parseInt($(this).data('value'), 10); // The star currently selected

    $('#rating_star').val(onStar)

    var stars = $(this).parent().children('li.star');

    for (i = 0; i < stars.length; i++) {
        $(stars[i]).removeClass('selected');
    }

    for (i = 0; i < onStar; i++) {
        $(stars[i]).addClass('selected');
    }

});


